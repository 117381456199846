import { AuthConfig } from "../type";
import { Amplify } from "aws-amplify";

export default class AmplifyConfiguration {
  /**
   * Initialize & configure Amplify Auth configurations.
   * @param config Argo Auth config object.
   */
  public static initialize(config : AuthConfig) : void {
    Amplify.configure({
      Auth: {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.clientId,
        authenticationFlowType: 'CUSTOM_AUTH',
        // belows are required for federated SSO
        oauth: {
          domain: config.domain,
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: config.redirectSignIn,
          redirectSignOut: config.redirectSignOut,
          responseType: 'code',
          identityProvider: 'Amazon'
        }
      }
    });
  }

  /**
   * Reconfigure Amplify Auth configurations with different set of authenticationFlowType.
   *
   * <p>
   * This is a temporary method to support user pool migration as the Cognito migration lambda trigger
   * can only be triggered with 'USER_PASSWORD_AUTH'.
   *
   *
   * @param config Argo Auth config object.
   * @param amplifyAuthenticationFlowType 'CUSTOM_AUTH' : 'ADMIN_USER_PASSWORD_AUTH'
   */
  public static updateAmplifyAuthenticationFlowType(config: AuthConfig , amplifyAuthenticationFlowType: string) : void {
    Amplify.configure({
      Auth: {
        region: config.region,
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.clientId,
        authenticationFlowType: amplifyAuthenticationFlowType,
        // belows are required for federated SSO
        oauth: {
          domain: config.domain,
          scope: ['email', 'profile', 'openid'],
          redirectSignIn: config.redirectSignIn,
          redirectSignOut: config.redirectSignOut,
          responseType: 'code',
          identityProvider: 'Amazon'
        }
      }
    });
  }
}
