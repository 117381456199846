import { getArgoConfig } from '../../window';
import * as LoggerUtility from '../../utils/logger/utility';
import { get as _get } from 'lodash';


const argoConfig = getArgoConfig();
const appName = _get(argoConfig, 'argo.applicationName', 'Argo');
const region = _get(argoConfig, 'argo.region', 'us-west-2');
const frameworkStage = _get(argoConfig, 'argo.frameworkStage', 'dev');
const cell = _get(argoConfig, 'argo.cell', 'cell-0');

export const metricConfig = {
  appName,
  region,
  frameworkStage,
  cell,
  browserName: LoggerUtility.getUserBrowserName(),
  deviceType: LoggerUtility.getUserDeviceType(),
  deviceOS: LoggerUtility.getUserDeviceOS(),
  locale: LoggerUtility.getUserLocale(),
  applicationVisitId: '',
  argoSessionId: '',
};

export const metricServiceName = `${appName}.${frameworkStage}.${region}.${cell}`;