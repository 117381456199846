import KatalMetricsPublisher from '@katal/metrics/lib/KatalMetricsPublisher';
import { Auth } from '@awsscm/awsscm-auth-manager/auth/AuthManager';

function sendMetric(metricsPublisher: KatalMetricsPublisher, startType: 'ApplicationWarmStart' | 'ApplicationColdStart') {
  const myInterval = setInterval(() => {
    if (typeof window.performance.getEntriesByType === 'function') {
      const perfNav = JSON.parse(JSON.stringify(window.performance.getEntriesByType('navigation')))[0];
      const perfResource = JSON.parse(JSON.stringify(window.performance.getEntriesByType('resource')))[0];
      if (perfNav.domComplete > 0) {
        const startTime = perfResource.requestStart;
        const domCompleteTime = perfNav.domComplete;
        const loadTime = domCompleteTime - startTime;
        metricsPublisher.newChildActionPublisherForMethod('InitialApplicationLoadTime').publishCounterMonitor(startType, loadTime);
        clearInterval(myInterval);
      }
    }
  }, 50);
}

/**
 * To be used on application render. Fires a timer metric for how long the application took to load. If the user was
 * already logged in, fire a warm start metric. Otherwise, fire a cold start metric.
 * @param metricsPublisher initialMetricsPublisher for sending Katal Metrics
 */
export const queueApplicationStartMetric = async (metricsPublisher: KatalMetricsPublisher) => {
  const loginStatus = await Auth.isUserLoggedIn();
  if (loginStatus) {
    sendMetric(metricsPublisher, 'ApplicationWarmStart');
  } else {
    sendMetric(metricsPublisher, 'ApplicationColdStart');
  }
}