/* eslint no-console: ["error", { allow: ["warn", "error", "debug", "log", "info"] }] */

const formatTime = (value: number) => ((value < 10) ? `0${value}` : `${value}`);

const formatMilliseconds = (value: number) => ((value < 10) ? `00${value}` : ((value < 100) ? `0${value}` : `${value}`));

const formatMessage = (component: string, value: string) => {
  const d = new Date();
  const time = `${formatTime(d.getHours())}:${formatTime(d.getMinutes())}:${formatTime(d.getSeconds())}:${formatMilliseconds(d.getMilliseconds())}`;

  return `[${time}] [${component}] ${value}`;
};

export const logDebug = (component: string, value: string): void => {
  console.debug(formatMessage(component, value));
};

export const logInfo = (component: string, value: string) : void => {
  console.info(formatMessage(component, value));
};

export const logWarn = (component: string, value: string) : void => {
  console.warn(formatMessage(component, value));
};

// export const logError = (component: string, value: string) => {
//   console.error(formatMessage(component, value));
// };
