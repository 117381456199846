import { UserManager, WebStorageStateStore } from "oidc-client";
import { AuthConfig } from "../type";

export default class OidcUserManagerFactory {
  /**
   * It takes auth configuration as input and generate an instance of {@link UserManager} to return.
   * @param config Argo Auth configuration object.
   */
  public static getUserManager(config: AuthConfig) : UserManager {
    return new UserManager({
      response_mode: 'query',
      authority: config.identityProviderUrl,
      client_id: config.clientId,
      redirect_uri: config.redirectSignIn,
      automaticSilentRenew: false,
      validateSubOnSilentRenew: false,
      loadUserInfo: false,
      silent_redirect_uri: config.redirectSignIn,
      post_logout_redirect_uri: config.redirectSignOut,
      filterProtocolClaims: true,
      response_type: config.responseType,
      scope: config.scope,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        issuer: config.identityProviderUrl,
        jwks_uri: config.jwkEndpoint,
        authorization_endpoint: config.authorizationEndpoint,
        token_endpoint: config.tokenEndpoint,
        userinfo_endpoint: config.userInfoEndpoint,
        end_session_endpoint: config.endSessionEndpoint,
      }
    });
  }
}