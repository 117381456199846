import { AuthSession, AuthUser, SessionServiceCredential } from "../type";
import { logInfo, logWarn } from "../utils/logger";
import { NonceManager } from "../utils/NonceManager";

export default class SessionServiceClient {
  public static sessSvcKey = "SessSvcResponse";

  private newTokenPrefix = "jwt-rfp";

  private readonly sessionServiceEndpoint : string;

  constructor(sessionSvcEndpoint : string) {
    this.sessionServiceEndpoint = sessionSvcEndpoint;
  }

  /**
   * Calls session service API endpoint to obtain session credentials by using user authentication token.
   * @param idToken id token issued by authentication provider.
   * @param nonce unencrypted uuidv4
   */
  public async getCredentialsFromToken(idToken: string, nonce: string|null) : Promise<SessionServiceCredential | null>{
    const apiUrl = this.sessionServiceEndpoint;

    // For the MidwayToken, it requires nonce validation to prevent a leak of the JWT from being leveraged by a malicious actor
    const authToken = nonce ? this.generateJWTRFPToken(idToken, nonce) : idToken;

    await fetch(apiUrl,{
      mode:'cors',
      method: 'GET',
      headers:{
        Authorization: authToken,
      }
    }).then(function(response : Response) {
      return response.json();
    })
      .then(responseData => {
        localStorage.setItem(SessionServiceClient.sessSvcKey, JSON.stringify(responseData));
        logInfo('AuthManager','SessionService call got response');
      }).catch(e => {
        logWarn("error calling session service", e);
        // Preserve error message in local storage rather than in response to Portal
        // Now users won't be blocked during login. Possible errors will be thrown when calling grpahV2
        localStorage.setItem(SessionServiceClient.sessSvcKey, JSON.stringify({"error": e.toString()}));
      });
    return this.parseSessionSvcResponse();
  }

  /**
   * Retrieves current active session for a given user.
   * If user has valid token but with no session service response in local storage,
   * it calls session service endpoint to obtain a new one.
   *
   * @param user AuthUser object where id token is included.
   */
  public async getSessionFromLocalStorage(user: AuthUser | null | undefined) : Promise<AuthSession | null> {
    if (user && window.localStorage.getItem(SessionServiceClient.sessSvcKey) && this.isSessionSvcResponseValid()) {
      return {
        idToken: user.id_token,
        credentials: this.parseSessionSvcResponse(),
      };
    } else if (user) {
      const nonce = NonceManager.getNonceFromStorage();
      await this.getCredentialsFromToken(user.id_token, nonce);
      return {
        idToken: user.id_token,
        credentials: this.parseSessionSvcResponse(),
      };
    } else {
      return null;
    }
  }

  private parseSessionSvcResponse() : SessionServiceCredential | null {
    const sessSvcResponseString = localStorage.getItem(SessionServiceClient.sessSvcKey);
    return sessSvcResponseString ? JSON.parse(sessSvcResponseString) : null;
  }

  private generateJWTRFPToken(idToken: string, nonce: string) : string {
    return this.newTokenPrefix + "|" + idToken + "|" + nonce;
  }

  /**
   * Check if the session service response stored in local storage is still valid and not expired
   */
  public isSessionSvcResponseValid() : boolean {
    const sessionSvcResponse = this.parseSessionSvcResponse();
    const currentTime = new Date().getTime() / 1000;
    if (sessionSvcResponse && sessionSvcResponse.credentials) {
      return sessionSvcResponse.credentials.expiration >= currentTime;
    } else {
      return false;
    }
  }
}
