import { AuthSession, SessionServiceCredential } from "@awsscm/awsscm-auth-manager/auth/type";
import jwtDecode, { JwtPayload } from "jwt-decode";

const DATE_TO_MS_CONSTANT = 1000;
const BUFFER_TIME_IN_MINUTES = 2;


/**
 *  Checks whether or not to refresh an Argo User Session
 *  A buffer of 2 minutes is kept to refresh before the credentials actually expires.
 *  When to Refresh a user Argo Session?
 *  1. Session service credentials have expired or contain bad data or not found in the localstorage.
 *  2. IDToken has expired or is not found in the localStorage.
 * @param userSession
 * @returns boolean
 */
export function shouldRefreshArgoSession(userSession: AuthSession) {
    if (!userSession) {
        return true
    }
    const idToken = userSession.idToken;
    const sessionServiceCredentials = userSession?.credentials;

    if (!idToken || !sessionServiceCredentials || !sessionServiceCredentials.credentials) {
        return true
    }

    const CURRENT_TIME_WITH_BUFFER = new Date(Date.now()).setMinutes(new Date(Date.now()).getMinutes() + BUFFER_TIME_IN_MINUTES);

    const sessionServiceExpiry = sessionServiceCredentials.credentials.expiration;
    const idTokenExpiry = jwtDecode<JwtPayload>(idToken).exp;

    // Check if the minimum of the two expiry times requires refresh
    return Math.min(sessionServiceExpiry, idTokenExpiry!) * DATE_TO_MS_CONSTANT < CURRENT_TIME_WITH_BUFFER;
}