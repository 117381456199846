import { UserManager } from "oidc-client";
import SessionServiceClient from "../sessionsvc/SessionServiceClient";
import { AuthConfig } from "../type";
import UserTokenManager from "../utils/UserTokenManager";

/**
 * Base class for auth handlers.
 */
export default abstract class AuthHandler {
  protected userManager : UserManager;

  protected sessionServiceClient : SessionServiceClient;

  protected authConfig : AuthConfig;

  protected userTokenManager : UserTokenManager;

  protected constructor(userManager : UserManager, sessionServiceClient : SessionServiceClient,
                        authConfig : AuthConfig) {
    const userKey = 'oidc.user:' + authConfig.identityProviderUrl + ':' + authConfig.clientId;
    this.authConfig = authConfig;
    this.userManager = userManager;
    this.sessionServiceClient = sessionServiceClient;
    this.userTokenManager = new UserTokenManager(userKey, authConfig);
  }
}