import React from "react";
import { Button, Box, Modal } from "@amzn/awsui-components-react-v3";
import { generateLegalAgreementText } from '../generateLegalAgreementText';
import strings from "../strings";
import '../legalAgreement.scss';

interface LegalAgreementModalProps {
  onSubmitContinue: () => void
  onSubmitDismiss: () => void
  siteTermsText: string
}

export const LegalAgreementModal = ({ onSubmitContinue, onSubmitDismiss, siteTermsText }: LegalAgreementModalProps) => {
  return (
    <Modal
      visible={true}
      header={strings.siteUse}
      footer={
        <Box id="first-lam-continue-button" float="right">
          <Button variant="primary" onClick={onSubmitContinue}>{strings.continue}</Button>
        </Box>
      }
      onDismiss={onSubmitDismiss}
      className="awsui-visual-refresh argo-legal-agreement-modal" // Modals exist outside the scope of the App DOM tree, so Polaris styles need to be re-applied
    >
      {generateLegalAgreementText({siteTermsText, onSubmitContinue})}
    </Modal>
  );
}