import * as CryptoJS from "crypto-js";

export class NonceManager {

    static NONCE_KEY = "rawNonce";

    static encryptNonce(nonce: string): string {
        return CryptoJS.SHA256(nonce).toString(CryptoJS.enc.Hex);

    }

    static setNonceToStorage(nonce: string): void {
        window.localStorage.setItem(this.NONCE_KEY, nonce);
    }

    static getNonceFromStorage(): string|null {
        return window.localStorage.getItem(this.NONCE_KEY);
    }
}
