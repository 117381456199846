import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from 'history';
import { get as _get } from 'lodash';
import { getArgoConfig } from '../../window';
import { ClientOptions, Event, EventHint } from '@sentry/types';
import { FrameworkStage } from '../../ArgoEnums';

/**
 * Based on the framework stage, set up default tags for Sentry Events before they are sent to the Sentry backend.
 * @param event A Sentry Event
 * @returns A modified Sentry Event, with the default tags configured.
 */
export function defaultBeforeSend(event: Event): Event {
    const config = getArgoConfig();
    const tags = ['region', 'frameworkStage', 'cell', 'isPersonalStack'];
    event.tags = event.tags || {};
    tags.forEach((element: string) => {
        const elementVal = _get(config, `argo.${element}`, "default_" + element);
        event.tags![element] = elementVal;
    });

    return event;
}

/**
 * Gets the default Sentry configuration. This configuration can be overridden using customizeConfig(),
 * but the default 'beforeSend' and 'integrations' parameters will be merged instead.
 * @returns The default configuration for Sentry
 */
export function getSentryDefaultConfig(): Partial<ClientOptions>{
    const config = getArgoConfig();
    const sentryDSN = _get(config, 'sentryDSN');
    const history = createBrowserHistory();
    const frameworkStage = _get(config, `argo.frameworkStage`) || '';

    const SentryDefaultConfig: Partial<ClientOptions> = {
        dsn: sentryDSN,
        environment: frameworkStage,
        integrations: [
            new BrowserTracing({
                tracingOrigins: ["amazon.com"],
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
        ],
        tracesSampleRate: 0.5,
        beforeSend(event: Event) {
            event = defaultBeforeSend(event);
            return event;
        }
    };

    return SentryDefaultConfig;
}

/**
 * Customizes the Sentry Configuration with application-supplied Sentry overrides (or merges, @see getSentryDefaultConfig )
 * @param customConfig Optional Sentry configuration overrides
 */
export function getSentryCustomizedConfig(customConfig: Partial<ClientOptions>): Partial<ClientOptions> {
    const defaultConfig = getSentryDefaultConfig();
    const customBeforeSend = customConfig.beforeSend;

    // instead of overridding the base 'beforeSend' function, merge their functionalities
    if ('beforeSend' in customConfig) {
        customConfig.beforeSend = (event: Event, hint: EventHint) => {
            const defaultEvent = defaultBeforeSend(event);
            // @ts-ignore
            const overridenEvent = customBeforeSend!(defaultEvent, hint);
            return overridenEvent;
        }
    }

    // instead of overriding the base 'integrations' list, concatenate them.
    if ('integrations' in customConfig) {
        customConfig.integrations = customConfig.integrations?.concat(defaultConfig.integrations!);
    }
    const sentryCustomizedConfig = {...defaultConfig, ...customConfig};
    return sentryCustomizedConfig;
}
