interface Metric {
    eventDetail?: string;
    eventValue?: string;
    eventType?: string;
    eventContext?: string;
    eventInteractionMetaData?: {};
    errorFormFields?: any;
    eventSource?: string;
}

type Panorama = (functionName: string, ...parameters: any) => void;

/**
 * Returns panorama window object
 * @returns { Panorama }
 */
const getPanorama = (): Panorama | undefined => {
    let panoramaWindow;
    try {
        panoramaWindow = window.panorama || window.top?.panorama;
    } catch (e) {
        console.log(
            `Cannot access panorama method, probably we are with in a cross origin iFrame.`
        );
    }
    return panoramaWindow;
};

/**
 * Check if Panorama method available in top window for same origin iFrames
 * @returns { boolean }
 */
const isPanoramaAvailableInTopWindow = (): boolean => {
    let isPanoramaAvailable = false;
    try {
        isPanoramaAvailable =
            window.top && window.top.panorama && typeof window.top.panorama === 'function';
    } catch (e) {
        console.log(`Cannot access window.top, probably we are with in a cross origin iFrame.`);
    }
    return isPanoramaAvailable;
};

/**
 * Checks if Panorama is enabled in current page
 * @returns { boolean }
 */
export const isPanoramaEnabled = (): boolean => {
    let isPanoramaEnabled = false;
    try {
        isPanoramaEnabled =
            (window.panorama && typeof window.panorama === 'function') ||
            isPanoramaAvailableInTopWindow();
    } catch (e) {
        console.log('Cannot identify if Panorama is available');
    }
    return isPanoramaEnabled;
};

/**
 * Checks if Panorama is enabled and empties existing metric buffer
 */
const checkIfCanEmptyBuffer = () => {
    if (isPanoramaEnabled()) {
        initialBuffer.forEach((metric) => {
            emitPanoramaCustomEvent(metric);
        });
    }
};

// Maintain metrics until Panorama is enabled on the page
const initialBuffer: Metric[] = [];

/**
 * Push the metrics to internal buffer until Panorama is enabled
 * @param { Metric } metric
 */
const pushToBuffer = (metric: Metric) => {
    initialBuffer.push(metric);
    checkIfCanEmptyBuffer();
};

/**
 * Emits Panorama custom event
 * @param { Metric } metric
 */
export const emitPanoramaCustomEvent = (metric: Metric) => {
    if (!isPanoramaEnabled()) {
        pushToBuffer(metric);
    } else {
        const panoramaWindow = getPanorama();
        if (panoramaWindow && typeof panoramaWindow === 'function') {
            panoramaWindow('trackCustomEvent', {
                ...metric,
                timestamp: Date.now(),
            });
        }
    }
};
